html > * {
  background-color: rgb(248, 249, 250) !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.errorfield {
  color: #dc3545;
  font-size: 80%;
  font-weight: bolder;
  list-style: none;
  padding: 1px;
}

.card-title {
  float: right;
  color: #62676b !important;
  font-weight: 600;
  margin-bottom: 0 !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  text-transform: uppercase;
}

label {
  font-size: 0.875rem;
  font-weight: 600;
  /* color: #495057; */
  margin-bottom: .5rem;
}

.group-box {
  border: solid #c0c0c0 1px;
  padding: 1rem;
  border-radius: 0.313rem;
  padding-bottom: 0.25rem;
  position: relative;
  margin-bottom: 1rem;
}

.group-box > legend {
  width: auto;
  padding: 0 7px;
  /* padding-right: 0.313rem;
  padding-left: 0.313rem; */
  margin-bottom: 0px !important;
  color: #697177 !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  position: absolute;
  font-size: .875rem;
  top: -10px;
  left: 10px;
  background: white;
}
.card-primary.card-outline {
  border-top: 3px solid #007bff;
}

.card-success.card-outline {
  border-top: 3px solid #28a745;
}

.hpersonal {
  float:left;
  margin-bottom: 15px;
  text-align: left !important;
  width:100%;
  padding-left:15px;
  font-weight: 600;
  font-size: 14px
}

.table-sm td {
  vertical-align: middle !important;
}